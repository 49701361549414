import { Component, inject, OnInit } from '@angular/core';

import { Router, RouterOutlet } from '@angular/router';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import {
  FfNgxAuthService,
  FfNgxCheckAuthHelper,
} from '@fagforbundet/ngx-components';
import { catchError } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  readonly #checkAuthHelper = inject(FfNgxCheckAuthHelper);
  readonly #ffNgxAuthService = inject(FfNgxAuthService);
  readonly #publicEventsService = inject(PublicEventsService);
  readonly #router = inject(Router);

  ngOnInit(): void {
    this.#checkAuthHelper
      .checkAuthAndRenew()
      .pipe(
        catchError(() => {
          const url = new URL(location.toString());
          if ('/' !== url.pathname) {
            this.#ffNgxAuthService.setStoredRedirectRoute(url);
          }
          return this.#router.navigate(['/']);
        }),
      )
      .subscribe();

    this.#publicEventsService
      .registerForEvents()
      .pipe(filter((event) => event.type === EventTypes.SilentRenewFailed))
      .subscribe(() => {
        // Reloading to have the current URL stored for redirect after authn
        location.reload();
      });
  }
}
